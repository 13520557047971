import React from "react"
import { graphql } from "gatsby"
// import Layout from "../components/layout"
import SEO from "../helpers/seo"
import Helmet from "react-helmet"

export default function Services({ data }) {
  const {
    headline,
    headlineTitle,
    headlineDescription,
    ourProcessTitle,
    ourProcessDescription,
    ourProcess,
    scope,
    seoTitle,
    seoKeywords,
  } = data.contentfulServicesPage

  const createMarkup = content => {
    return { __html: content }
  }

  return (
    <React.Fragment>
      <Helmet>
        <body className="services-page" />
      </Helmet>
      <SEO title={seoTitle} keywords={seoKeywords} />
      <div className="content-wrapper">
        <section className="intro">
          <div
            className="intro-title"
            dangerouslySetInnerHTML={createMarkup(
              headline.content[0].content[0].value
            )}
          />
          <div className="service">
            <div className="title">{headlineTitle}</div>
            <p>{headlineDescription.headlineDescription}</p>
          </div>
        </section>
        <section className="services">
          {scope.map(node => (
            <div className="service" key={node.id}>
              <div className="title">{node.scopeHeader}</div>
              <ul>
                {node.scope.map((v, i) => (
                  <li key={i}>{v}</li>
                ))}
              </ul>
            </div>
          ))}
        </section>
        <section className="our-process">
          <div className="title">{ourProcessTitle}</div>
          <p>{ourProcessDescription.ourProcessDescription}</p>
          <div className="process-wrapper">
            {ourProcess.map(node => (
              <div className="service" key={node.id}>
                <h1>{node.title}</h1>
                <div className="title">{node.subTitle}</div>
                <p>{node.content.content}</p>
              </div>
            ))}
          </div>
        </section>
      </div>
    </React.Fragment>
  )
}

export const servicesQuery = graphql`
  query servicesQuery {
    contentfulServicesPage {
      ourProcess {
        id
        title
        subTitle
        content {
          content
        }
      }
      headlineTitle
      headline {
        content {
          content {
            value
          }
        }
      }
      headlineDescription {
        headlineDescription
      }
      seoKeywords
      seoTitle
      scope {
        id
        scopeHeader
        scope
      }
      ourProcessDescription {
        ourProcessDescription
      }
      ourProcessTitle
    }
  }
`
